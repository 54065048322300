import {Button, Grid} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from "react";
  import { useNavigate } from "react-router-dom";
import logo from '../icons/logo.png';
import './Header.css';
import CopyrightIcon from "@material-ui/icons/Copyright";

const PlaysHeader = ({showBackButton, showAccountIcon, data, backGoesBack, backUri = null}) => {
  const navigate = useNavigate();
  // console.log('Data = ', data)
  return (
    <Grid container direction="row" className="game"
    justify="space-around"
    alignItems="center"
    >
     <Grid item xs={3}>
       {showBackButton ?
      <Button style={{color: "white", marginLeft: -30}}
              onClick={(e) => backGoesBack ? navigate(-1) : navigate(backUri ? backUri : "/plays")}>
        <ArrowBackIcon  />
      </Button> : null}
      </Grid>
      <Grid item xs={3} style={{textAlign: 'center'}}>
        <img src={logo} alt='logo'/>
      </Grid>
      <Grid item xs={3} style={{textAlign: 'right'}}>
        {/*<AccountCircleSharpIcon style={{ color: '#FFC51F' }}/>*/}
        {showAccountIcon && data && data.user && <div style={{flexDirection: 'row', height: 24, lineHeight: "24px", paddingLeft: 14, paddingRight: 6, float: 'right', borderRadius: 10, border: '1px solid rgba(253, 199, 52, 1)'}}>
          <CopyrightIcon style={{float: 'right', height: 14, marginTop: 5.5, color: '#FDC934'}}/>
          <div style={{float: 'right', color: '#FDC934', fontFamily: 'Kanit', height: 14, fontSize: 14, fontWeight: 500, }}>
          {data.user.total_coins}
        </div></div>}
      </Grid>
    </Grid>
  );
};

export default PlaysHeader;
