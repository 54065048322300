import PlaysHeader from "../../ui/PlaysHeader";
import {useStyles} from "../styles";
import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Grid, Modal, TextField, Typography} from "@material-ui/core";
import ClockIcon from "@material-ui/icons/AccessAlarm";
import BasketballIcon from "@material-ui/icons/SportsBasketball";
import FootballIcon from "@material-ui/icons/SportsFootball";
import PlayerIcon from "@material-ui/icons/Person";
import {useTournStyles} from "./ListingStyles";
import {getTournaments} from "../../../hooks/useTournaments";
import {joinTournament, leaveTournament} from "../../../hooks/useTournaments";
import {useNavigate, useLocation} from "react-router-dom";
import {utcDateStrToDate} from "../../../utils/dateUtils";
import querystring from "querystring";
import TournamentMenu from "../../ui/tournament/TournamentMenu";
import constants from "../../../constants";
import {getInviteUrl} from "../../../utils/inviteUtils";
import CreateTournamentButton from "../../ui/CreateTournamentButton";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
};
// const tournaments = [
//     {
//         title: "NBA Playoffs Tournament Adf asdflakfjasldfjadsf asdfljaldfjaflasjfd",
//         type: "Playcaller Global Tournament",
//         startDate: '2022-04-18 03:00',
//         home: 'Milwaukee Bucks',
//         away: 'Chicago Bulls',
//         activeParticipants: 12,
//         totalParticipants: 20,
//         entryFee: 0,
//         prize: 100,
//         isUserEntered: true
//     }
// ]

function TournamentListing({token, setIsShowBottomBar, setSelectedTabIdx, setTournamentId, setSportInApp}) {
    const [sport, setSport] = useState(null)
    const [tournaments, setTournaments] = useState([])
    const [newlyEnteredTournTitle, setNewlyEnteredTournTitle] = useState(null)
    const [isInviteLinkModOpen, setIsInviteLinkModOpen] = useState(false)
    const [linkModalCopyText, setLinkModalCopyText] = useState("COPY")
    const [linkModalTitle, setLinkModalTitle] = useState('')
    const [linkModalUrl, setLinkModalUrl] = useState('https://blah.com/df92dfd4444')

    const classes = useStyles()
    const tournStyles = useTournStyles()
    const navigate = useNavigate()
    const location = useLocation()
    let interval = useRef();
    // let inviteLinkText = useRef(null);

    useEffect(() => {
        if (checkToken()) {
            // console.log('token = ', token)
            setIsShowBottomBar(true);
            setSelectedTabIdx(0)

            // clear this out - primary motivation here is if a user selected a
            // team on a screen after this one and pressed back to return here
            localStorage.setItem("team", null)
            localStorage.setItem("tournament", null)
            setTournamentId(0)

            let sport = location?.state?.sport
            if (sport) {
                localStorage.setItem("sport", sport)
            } else {
                sport = localStorage.getItem("sport")
                console.log('Read Sport from localStorage =', sport)
            }
            // console.log('Listing sport = ', sport);
            setSport(sport)
            setSportInApp(sport)

            if (token && token.access) {
                loopQueryTournaments(token, sport)
            } else {
                // FIXME
                const storedToken = localStorage.getItem("token")

                if (storedToken) {
                    let values = storedToken.split(";");
                    if (new Date(values[1]) < new Date()) {
                        localStorage.removeItem("token");
                        navigate("/login")
                        return
                    }
                    const parsedToken = JSON.parse(values[0]);
                    loopQueryTournaments(parsedToken, sport)
                } else {
                    navigate("/login")
                    return
                }
            }

            let params_str = window.location.search
            if (params_str && params_str.length > 1) {
                if (params_str.indexOf("?") === 0) {
                    params_str = params_str.substring(1)
                }
                const qp = querystring.parse(params_str);
                // console.log('qp = ', qp)
                if (qp && qp.checkout_success && qp.tournament_title) {
                    // console.log('qp.tournament_title = ', qp.tournament_title)
                    setNewlyEnteredTournTitle(qp.tournament_title);
                }
            }
        }

        return function cleanup() {
            setIsShowBottomBar(false)
            if (interval && interval.current) {
                clearInterval(interval.current)
            }
        }
    }, [])

    useEffect(() => {
        // console.log('token Listing = ', token)
        // if (token && token.access) {
        //     loopQueryTournaments(token)
        // } else {
        //     // navigate("/")
        // }
        // return function cleanup() {
        //     if (interval && interval.current) {
        //         clearInterval(interval.current)
        //     }
        // }
    }, [token])

    const handleOpenLinkModal = () => {
        setIsInviteLinkModOpen(true);
        // console.log('blah = ', inviteLinkText.getValue())
    }
    const handleCloseLinkModal = () => setIsInviteLinkModOpen(false);
    const handleCopyLinkModal = () => {
        // setIsInviteLinkModOpen(false);
        navigator.clipboard.writeText(linkModalUrl)
        setLinkModalCopyText("COPIED!")
        setTimeout(() => {
            setLinkModalCopyText("COPY")
        }, 2000)
    }

    function fetchTournamentInvite(token, tournament) {
        // // console.log('tournament = ', tournament)
        // if (tournament) {
        //     createTournamentInvite(token, tournament.id).then(response => {
        //         // console.log('response = ', response.data)
        //         if (response?.data?.link_id) {
        //             setLinkModalUrl(constants.API_HOST + '/app/tournament/invite/open?id='
        //                 + response?.data?.link_id)
        //         } else {
        //             alert("There was an error - no link returned. Please try again")
        //         }
        //     }).catch(error => {
        //         alert(error)
        //     })
        // }

        setLinkModalUrl(getInviteUrl(tournament.inviteCode))
    }

    function checkToken() {
        const userToken = localStorage.getItem("token");
        if (userToken) {
            let values = userToken.split(";");
            // console.log('values = ', values)
            if (new Date(values[1]) < new Date()) {
                localStorage.removeItem("token");
                window.location = "/login"
                return false
            } else {
                // const token = JSON.parse(values[0]);
                // setToken(token);
            }
        } else {
            window.location = "/login"
            return false
        }
        return true
    }

    const fetchTournaments = (token, sportArg) => {
        getTournaments(token, null, sportArg).then(response => {
            // console.log('tournaments response = ', response)
            setTournaments(response?.data)
        }).catch((error) => {
            console.log("Error getting the list of tournaments:: ", error);
            if (error?.response?.status === 403 || error?.response?.status === 400) {
                localStorage.removeItem("token");
                window.location = "/login"
            }
        })
    }

    const loopQueryTournaments = (token, sport) => {
        // console.log('loopQueryTournaments')
        fetchTournaments(token, sport)
        interval.current = setInterval(() => {
            fetchTournaments(token, sport)
        }, 4000)
    }

    function onTournamentPress(event, tournament) {
        // localStorage.setItem("gameSelected", "NBA")
        // localStorage.setItem("gameId", game_id)
        // navigate("/nba/game")

        if (checkToken()) {
            // if (tournament.isUserEntered) {
                localStorage.setItem("tournament", JSON.stringify(tournament));
                setTournamentId(tournament.id);

                // navigate("/nba/game");
                // navigate("/game");
                navigate(
                    "/tournament/details",
                    {
                        state: {
                            tournament,
                            sport
                        }
                    }
                );
            // }
        }
        // console.log('onTournamentPress')
        event.stopPropagation()
    }

    function onJoinOrLeaveBtnPress(event, tournament) {
        if (checkToken()) {
            if (tournament.isUserEntered) {
                leaveTournament(token, tournament.id).then(response => {
                    // console.log('Response1 = ', response)
                    fetchTournaments(token)
                });
                setNewlyEnteredTournTitle(null);
            } else {
                joinTournament(token, tournament.id).then(response => {
                    // console.log('Response2 = ', response)
                    fetchTournaments(token)
                })

                // // FIXME - use token instead of user id
                // window.location.href = constants.API_HOST + '/app/checkout_preview?user_id='
                //     + token.id + "&tournament_id=" + tournament.id;
            }
        }
        event.stopPropagation()
    }

    function onGameRulesBtnPress(event, tournament) {
        navigate("/game_rules")
    }

    function formatStartDate(start_date) {
        const days_of_week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let hours = start_date.getHours()
        let am_pm_str
        if (hours >= 12) {
            am_pm_str = "PM"
        } else {
            am_pm_str = "AM"
        }
        let hour_str
        if (hours > 12)
            hour_str = (hours - 12).toString()
        else
            hour_str = hours.toString()
        let mins_str
        if (start_date.getMinutes() < 10) {
            mins_str = "0" + start_date.getMinutes().toString()
        } else {
            mins_str = start_date.getMinutes().toString()
        }
        return days_of_week[start_date.getDay()] + ", " + months[start_date.getMonth()]
            + " " + start_date.getDate() + " " + hour_str + ":" + mins_str + " " + am_pm_str
    }

    function onCreateInviteBtnPress(tournament) {
        setLinkModalTitle('TOURNAMENT INVITE')
        handleOpenLinkModal()
        fetchTournamentInvite(token, tournament)
    }

    function onShareBtnPress(tournament) {
        setLinkModalTitle('SHARE TOURNAMENT')
        setLinkModalUrl(constants.APP_HOST + '/tournament/details?id=' + tournament.id + "&sport="
            + encodeURIComponent(tournament.sport))
        handleOpenLinkModal()
    }

    function renderTournamentCell(tournament) {
        const have_free_space = tournament.activeParticipants < tournament.totalParticipants
        const start_date = utcDateStrToDate(tournament.startDatetimeUtc)
        // console.log('start_date = ', start_date.getTime())
        // console.log(tournament)
        // console.log("Token = ", token)
        const is_started = start_date.getTime() < Date.now()
        const start_date_str =formatStartDate(start_date)
        const show_tournament_menu_button = tournament.only_invited_players !== 1 || tournament.players_can_invite === 1
            || token.id === tournament.createdByUserId
        const show_create_invite_menu_item = show_tournament_menu_button && tournament.only_invited_players === 1

        return (<div className={tournStyles.tournCell}
                        onClick={() => {
                            // onTournamentPress(tournament)
                        }}>
            <div className={tournStyles.tourCellTopRow}>
                <div className={tournStyles.tournTitle}>{tournament.title}</div>
                {tournament.isUserEntered && <div className={tournStyles.tournTopBadge}>JOINED</div>}
            </div>
            <div className={tournStyles.tourCellTopRow2}>
                <div className={tournStyles.tournSubTitle}>{tournament.type}</div>
                {show_tournament_menu_button && <TournamentMenu
                    style={{width: '30%', height: 14, float: "right", lineHeight: "14px", textAlign: "right"}}
                    buttonStyle={{color: '#FFF', marginRight: 4, fontSize: 14, width: '100%', justifyContent: "flex-end"}}
                    buttonText={"..."}
                    onCreateInviteBtnPress={() => {
                        onCreateInviteBtnPress(tournament)
                    }}
                    onShareBtnPress={() => {
                        onShareBtnPress(tournament)
                    }}
                    showCreateInviteMenuItem={show_create_invite_menu_item}
                />}
            </div>
            <div className={tournStyles.tournTileDetailsRow}>
                <div className={tournStyles.tournTileDetailsRowIcon}>
                    <ClockIcon className={tournStyles.tournTileDetailsRowIconImage} />
                </div>
                <div className={tournStyles.tournScheduleText}>
                    {(is_started ? "Started " : "") + start_date_str}
                </div>
            </div>
            <div className={tournStyles.tournTileDetailsRow}>
                <div className={tournStyles.tournTileDetailsRowIcon}>
                    {tournament.sport === constants.SPORT_FOOTBALL && <FootballIcon className={tournStyles.tournTileDetailsRowIconImage} />}
                    {tournament.sport === constants.SPORT_BASKETBALL && <BasketballIcon className={tournStyles.tournTileDetailsRowIconImage} />}
                </div>
                <div className={tournStyles.tournTeamsTextContainer}>
                    <div className={tournStyles.tournTeamsTeam}>
                        {tournament.away}
                    </div>
                    <div className={tournStyles.tournTeamsTextVs}>
                        vs
                    </div>
                    <div className={tournStyles.tournTeamsTeam}>
                        {tournament.home}
                    </div>
                </div>
            </div>
            <div className={tournStyles.tournTileDetailsRow}>
                <div className={tournStyles.tournTileDetailsRowIcon}>
                    <PlayerIcon className={tournStyles.tournTileDetailsRowIconImage} />
                </div>
                <div className={tournStyles.tournTeamsTextContainer}>
                    <div className={tournStyles.tournParticipantCount}>
                        {tournament.activeParticipants} /
                    </div>
                    <div className={tournStyles.tournParticipantTotal}>
                        {tournament.totalParticipants}
                    </div>
                </div>
            </div>
            <div className={tournStyles.tournTileBottomRow}>
                {tournament.created_by_username && <div className={tournStyles.createdByWrap}><div className={tournStyles.createdByLabel}>
                    Created by
                </div>
                <div className={tournStyles.createdByUser}>
                    &nbsp;{tournament.created_by_username}
                </div></div>}
                <div className={tournStyles.prize}>
                    <div className={tournStyles.prizeLabel}>
                        Prize:
                    </div>
                    <div className={tournStyles.prizeNumber}>
                        ${tournament.prize}
                    </div>
                    {/*<img src={CoinIcon} className={tournStyles.coin}/>*/}
                </div>
                <div className={tournStyles.entryFee}>
                    <div className={tournStyles.entryFeeLabel}>
                        Entry fee
                    </div>
                    <div className={tournStyles.entryFeeNumber}>
                        ${tournament.entryFee}
                    </div>
                    {/*<img src={CoinIcon} className={tournStyles.coin}/>*/}
                </div>
            </div>
            <Button className={tournStyles.joinOrLeaveBtn} onClick={(e) => {
                onTournamentPress(e, tournament)
            }}>
                DETAILS
            </Button>
            {false && tournament.isUserEntered && <Button className={tournStyles.joinOrLeaveBtn} onClick={(e) => {
                onGameRulesBtnPress(e, tournament)
            }}>
                GAME RULES
            </Button>}
            {false && tournament.isUserEntered && <Button className={tournStyles.joinOrLeaveBtn} onClick={(e) => {
                onJoinOrLeaveBtnPress(e, tournament)
            }}>
                LEAVE
            </Button>}
            {false && !tournament.isUserEntered && have_free_space && <Button className={tournStyles.joinOrLeaveBtn} onClick={(e) => {
                onJoinOrLeaveBtnPress(e, tournament)
            }}>
                JOIN
            </Button>}
            {!tournament.isUserEntered && !have_free_space && <div className={tournStyles.tournIsFull}>
                TOURNAMENT IS FULL
            </div>}
        </div>)
    }

    return (<div>
        <Modal
            open={isInviteLinkModOpen}
            onClose={handleCloseLinkModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={modalStyle}>
                <div style={{width: '100%', textAlign: 'center', height: 32, lineHeight: '32px', fontSize: 16, marginTop: 10, fontFamily: 'Kanit'}}>
                    {linkModalTitle}
                </div>
                <div style={{width: '100%', textAlign: 'center', marginTop: 20, marginBottom: 20}}>
                    <TextField
                        variant={"filled"}
                        label={"Link"}
                        value={linkModalUrl}
                        style={{color: '#000', width: "90%"}}
                        InputProps={{
                            readOnly: 'true'
                        }}
                    />
                </div>
                <Button style={{
                    width: '90%',
                    marginLeft: '5%',
                    marginBottom: 20,
                    letterSpacing: '0.1em',
                    fontSize: 16,
                    fontFamily: 'Kanit'
                }} onClick={handleCopyLinkModal}>{linkModalCopyText}</Button>
                <Button style={{
                    width: '90%',
                    marginLeft: '5%',
                    marginBottom: 20,
                    letterSpacing: '0.1em',
                    fontSize: 16,
                    backgroundColor: '#FDC934',
                    fontFamily: 'Kanit'
                }} onClick={handleCloseLinkModal}>CLOSE</Button>
            </Box>
        </Modal>
        <Box className={classes.body}>
        <PlaysHeader showBackButton={true} showAccountIcon={true} data={token}/>
        <Grid
            container
            direction="column"
            justify="space-around"
            spacing={2}
            className={classes.primLeaderboard}
            style={{fontFamily: 'Kanit'}}
        >
            <Grid item>
                <Typography className={tournStyles.sectionTitle} align="left" style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                }}>{sport && sport !== 'null' ? sport : constants.SPORT_FOOTBALL} 2025 TOURNAMENTS</Typography>
                <Typography className={tournStyles.sectionTitle2} align="left">Only live or upcoming tournaments you've joined or created are shown below</Typography>

                {newlyEnteredTournTitle !== '' && newlyEnteredTournTitle !== null && <Typography className={tournStyles.sectionTitle} align="left" style={{
                    color: 'white',
                    marginTop: 12,
                    marginBottom: 12,
                }}>You have successfully entered the tournament "{newlyEnteredTournTitle}"</Typography>}
            </Grid>
            <Grid item style={{marginBottom: 20, marginLeft: 10, marginRight: 10, width: "calc(100% - 20px)"}}>
                <CreateTournamentButton/>
            </Grid>
            { tournaments.map(item => (
                renderTournamentCell(item)
            )) }
        </Grid>
    </Box></div>)
}

export default TournamentListing;
