import axios from "axios";
import constants from '../constants'

export const getLiveAndUpcomingGames = (token) => {
    // return axios.get(constants.API_HOST + '/app/nba/live_games.json')
    // const curr_date_str = getCurrDateStr()
    // console.log('Date = ' + yyyy + mm + dd)
    // return axios.get('https://data.nba.net/prod/v2/20210626/scoreboard.json')
    // return axios.get('https://data.nba.net/prod/v2/' + '20210627' + '/scoreboard.json')
    // return axios.get('https://data.nba.net/prod/v2/' + curr_date_str + '/scoreboard.json')
    const headers = {
        'Authorization': 'Bearer ' + token.access
    }
    return axios.get(constants.API_HOST + "/app/nba/live_and_upcoming.json", {
        headers,
    })
}

// export const getTournaments = (token) => {
//     // return axios.get(constants.API_HOST + '/app/nba/live_games.json')
//     // const curr_date_str = getCurrDateStr()
//     // console.log('Date = ' + yyyy + mm + dd)
//     // return axios.get('https://data.nba.net/prod/v2/20210626/scoreboard.json')
//     // return axios.get('https://data.nba.net/prod/v2/' + '20210627' + '/scoreboard.json')
//     // return axios.get('https://data.nba.net/prod/v2/' + curr_date_str + '/scoreboard.json')
//     const headers = {
//         'Authorization': 'Bearer ' + token.access
//     }
//     const params = {
//         user_id: token.id
//     }
//     // console.log('Headers = ', headers)
//     return axios.get(constants.API_HOST + "/app/nba/tournaments.json", {
//         headers,
//         params
//     })
// }
