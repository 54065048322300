import * as React from 'react';
import {ButtonBase, Menu, MenuItem} from "@material-ui/core";

export default function TournamentMenu({style, buttonStyle, buttonText, onCreateInviteBtnPress, onShareBtnPress, showCreateInviteMenuItem}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const myOnCreateInviteBtnPress = (event) => {
        onCreateInviteBtnPress()
        handleClose()
    }

    const myOnShareBtnPress = (event) => {
        onShareBtnPress()
        handleClose()
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={style ? style : {}}>
            <ButtonBase
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={buttonStyle ? buttonStyle : {}}
            >{buttonText}</ButtonBase>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {showCreateInviteMenuItem && <MenuItem onClick={myOnCreateInviteBtnPress}>Create Invite</MenuItem>}
                <MenuItem onClick={myOnShareBtnPress}>Share</MenuItem>
            </Menu>
        </div>
    );
}
