import {Container, Button, ButtonGroup, Grid, Typography} from "@material-ui/core";
import querystring from 'querystring'
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {configureNFLJsonData, configureNBAJsonData} from "../../service/placeBet";
import "./BottomBar.css";
import constants from "../../constants";
// import CoinIcon from "../icons/coin.png";
import CopyrightIcon from "@material-ui/icons/Copyright";

const OddsPotential = ({ parentOdds, odds, bet}) => {

  const classes = useStyles();
  const [oddsBtn, setOddsBtn] = useState(0);
  const [potentialReturn, setPotentialReturn] = useState(0);

  useEffect(() => {
    // console.log('parentOdds = ', parentOdds)
    // console.log('Odds = ', odds)
    // console.log('Bet = ', bet)
    const oddsF = parseFloat(odds)
    if (!parentOdds || parentOdds === 0) {
      if (oddsF === 0) {
        setOddsBtn(0);
        setPotentialReturn(0);
      } else {
        // let oddsBtn = parseFloat(odds);
        // setOddsBtn((oddsBtn).toFixed(2));
        setOddsBtn(oddsF)
        if(!isNaN(oddsF * bet)) {
          setPotentialReturn((oddsF * bet).toFixed(2));
        } else {
          setPotentialReturn(0)
        }
      }
    } else if (!oddsF) {
      setOddsBtn(parentOdds);
      if(!isNaN(parentOdds * bet)) {
        setPotentialReturn((parentOdds * bet).toFixed(2));
      } else {
        setPotentialReturn(0)
      }
    } else {
      // let oddsBtn = 0;
      // if(parentBet !== 0) {
      //   oddsBtn = (1 + parseFloat(parentBet)) * (1 + parseFloat(odds));
      // }
      // else {
      //   oddsBtn = (1 + parseFloat(odds));
      // }
      // setOddsBtn(oddsBtn.toFixed(2));
      // if(!isNaN(odds * bet) && !isNaN(parentBet)) {
      //   setPotentialReturn((oddsBtn * bet).toFixed(2));
      // }

      // const parlay_odds = ((1 + parentOdds) * (1 + odds) - 1)
      // setOddsBtn(parlay_odds.toFixed(2))
      const parlay_odds = oddsF
      // console.log('parlay_odds = ', parlay_odds)
      // console.log('(parlay_odds + 1) = ', (parlay_odds + 1))
      // console.log('bet = ', bet)
      // console.log('payout = ', (parlay_odds + 1) * bet)

      setOddsBtn(parlay_odds)
      if(!isNaN(parlay_odds * bet)) {
        setPotentialReturn((parlay_odds * bet).toFixed(2));
      } else {
        setPotentialReturn(0)
      }
    }
  }, [odds, parentOdds, bet]);

  // console.log('oddsBtn = ', oddsBtn)

  return (
    <Grid
      container
      className={classes.odds}
      direction="row"
      justify="space-between"
      spacing={7}
      alignItems="center"
    >
      <Grid item>
        <Grid container direction="row" className="odds-container">
          <Grid item>
            {" "}
            <div className="odds">Odds</div>{" "}
          </Grid>
          <Grid item>
            {" "}
            <div className="oddsNum">
              {(isNaN(parentOdds) || oddsBtn === 0 || isNaN(oddsBtn)) ? '' : parseFloat(oddsBtn).toFixed(1) + "x"}
            </div>{" "}
          </Grid>
        </Grid>
      </Grid>
      <Grid item></Grid>
      <Grid item xs={5} >
        <Grid container direction="row" className="odds-container" style={{justifyContent:'flex-end', alignSelf: 'flex-end'}}>
          {(isNaN(odds * bet)) ? (
            <div style={{flexDirection: 'row', alignSelf: 'flex-end'}}>
              <Grid item xs={10} style={{float: "right"}}>
                <div className="returnNum"></div>
              </Grid>
              <Grid item style={{float: "right"}}>
                <div className="returns">Potential Return</div>{" "}
              </Grid>
            </div>
          ) : (
            <div style={{flexDirection: 'row', alignSelf: 'flex-end'}}>
              <Grid item xs={10} style={{float: "right"}}>
                <div className= {(isNaN(potentialReturn) || potentialReturn === 0 || potentialReturn === "0.00") ? "returns" : "returnNum"}>
                  <Typography className={"returnNumText"} style={{float: "left"}}>{(isNaN(potentialReturn) || potentialReturn === 0 || potentialReturn === "0.00") ? " -": potentialReturn}</Typography>
                  <CopyrightIcon style={{float: 'left', height: 16, marginTop: 3, color: '#FDC934'}}/>
                </div>
              </Grid>
              <Grid item style={{float: "right"}}>
                <div className="returns">Potential Return</div>{" "}
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  bottom: {
    // position: "absolute",
	// 	left:0,
    width: "100%",
    background: "#2a2d35",
    // bottom: 0,
    zIndex: 99999
  },
  odds: {
    paddingTop: 5,
    paddingBottom: 15,
    color: "#FFF",
  },
  buttonGroup: {
    width: "100%",
    height: 48,
    paddingTop: "12px",
  },
  activeButton: {
    color: "black",
    width: "100%",
    paddingTop: "7px",
    borderColor: "transparent",
    background: "#FDC934",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.04em",
    height: 44,
    "&:hover": {
      color: "black",
      background: "#FDC934",
    },
    "&:active": {
      color: "black",
      background: "#FDC934",
    },
    "&:focus": {
      color: "black",
      background: "#FDC934",
    },
  },
  button: {
    color: "white",
    width: "100%",
    borderColor: "transparent",
    background: "#1E2128",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.04em",
    height: 44,
    "&:hover": {
      color: "black",
      background: "#FDC934",
    },
    "&:active": {
      color: "black",
      background: "#FDC934",
    },
    "&:focus": {
      color: "black",
      background: "#FDC934",
    },
  },
  betButton: {
    color: "#FFF",
    width: "100%",
    height: 48,
    background: "#1E2128",
    textTransform: "none",
    borderRadius: "4px",
    padding: 20,
    bottom: 5,
    textAlign: "center",
    fontFamily: "Kanit",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.04em",
    "&:disabled": {
      color: "rgba(255, 255, 255, 0.5)",
      opacity: 4,
    },
    "&:hover": {
      color: "black",
      backgroundColor: "#FDC934",
    },
    "&:active": {
      color: "black",
      background: "#FDC934",
    },
    "&:focus": {
      color: "black",
      background: "#FDC934",
    },
    "&:enabled": {
      color: "black",
      backgroundColor: "#FDC934",
    }
  },
});

const DEFAULT_BET_AMOUNTS = [1, 5, 10, 20, 50, 100];
export default function BetBar({
                                 type,
                                 gameType,
                                 odds,
                                 hide,
                                 tgt,
                                 token,
                                 setShowBets,
                                 setBetMountClicked,
                                 betMountClicked,
                                 activeShow,
                                 setBetAmount,
                                 setShowPotentialReturn,
                                 combineBet,
                                 setNbaPlayersVal,
                                 setMainShotZoneOpt,
                                 selectedButton,
                                 setConbineBet,
                                 setNextPossessionOptionClicked,
                                 tournament,
                                 gsData,
                                 userBetsStats,
                                 setShowPauseScreen,
                                 nbaTeam,
                                 setBetErrorMsg=null,
                                 showBetErrorAlert=null
                               }) {
  const classes = useStyles();
  const [bet, setBet] = useState(null);
  const [parentBet, setParentBet] = useState(type.odds.slice(0, -1));
  const [betOdd , setBetOdd] = useState(0);

  // console.log('gsData = ', gsData)

  useEffect(() => {
    let odds = type.odds.split(" - ");
    setParentBet(odds[0].slice(0, -1));
  }, []);

  useEffect(() => {
    // console.log('useEffect odds = ', odds)
    // console.log('useEffect activeShow = ', activeShow)
    if (activeShow) {
      setBetOdd(odds);
    }
  }, [odds, activeShow]);

  useEffect(() => {
    if (hide || !activeShow) {
      setBetOdd(0);
      setBet(0);
    }
  }, [hide, activeShow]);

  useEffect(() => {
    setParentBet(type.odds.slice(0, -1));
    // console.log('BetList Tournament = ', tournament)
    if (tournament?.score_system === 'most_bets_won') {
      setBet(1)
    }
  }, [type, parentBet]);

  useEffect(() => {
    let odds = type.odds.split(" - ");
    setParentBet(odds[0].slice(0, -1));
  }, [type, parentBet, odds]);

  const handleSubmit = () => {
    let data = {};

    if (userBetsStats && userBetsStats.open_calls && nbaTeam && userBetsStats.open_calls[nbaTeam]
        && userBetsStats.open_calls[nbaTeam] > 0) {
      setShowPauseScreen(true)
      return
    }

    const is_most_bets_won_scoring = tournament?.score_system === 'most_bets_won'
    let bet_amt = bet
    if (is_most_bets_won_scoring) {
      bet_amt = 1
    }

    if (!is_most_bets_won_scoring && isNaN(odds * bet_amt)) {
      alert("Couldn't calculate payout properly");
      return;
    }

    if (!bet_amt) {
      alert("Add the quantity of your bet");
      return;
    }
    // setToken({
    //   ...token,
    //   total_coins: token.total_coins - odds * bet * parentBet,
    // });

    // console.log('odds = ', odds)
    // console.log('tournament = ', tournament)
    if (gameType === "NFL")
      data = configureNFLJsonData(type, bet_amt, tgt, odds, tournament && tournament.id,
        gsData?.offense_team, gsData?.last_situation_id);
    else
      data = configureNBAJsonData(type, bet_amt, tgt, combineBet, selectedButton, odds,
        tournament && tournament.id);

    const headers = {
      'Authorization': 'Bearer ' + token.access
    }

   axios.post( constants.API_HOST + '/app/bet/create', querystring.stringify(data), {
     headers
   })
   // axios.post( 'http://localhost:8000/app/bet/create', querystring.stringify(data))
    .then(response => {
      // console.log('Bet Response = ', response)
      if (response?.data?.error) {
        if (setBetErrorMsg) {
          let errMsg = response.data.error
          if (response.data.newOdds) {
            errMsg = errMsg + ", New Odds = " + response.data.newOdds
          }
          setBetErrorMsg(errMsg);
        }
        if (showBetErrorAlert)
          showBetErrorAlert();
        setShowPotentialReturn(false);
      } else {
        setShowBets(true);
        setBetAmount(bet);
        setShowPotentialReturn(true);

        if (setNextPossessionOptionClicked)
          setNextPossessionOptionClicked(false) // need this so the betting section buttons (2pt shot, 3pt shot, etc) reload odds
      }

      if(gameType === "NBA-MiamiVsMilwaukee" || gameType === "NBA-PhoenixVsLALakers") {
        setNbaPlayersVal([]);
        setMainShotZoneOpt({
          isClicked: false,
          mainShotId: "",
          playerId: ""
        });
        setConbineBet({
          mainBet: "",
          playerBet: ""
        })
      }
    })
    .catch((error) => {
      console.log("Error occurred while placing bet: ", error)
      if (error?.response?.status === 403 || error?.response?.status === 400) {
        localStorage.removeItem("token");
        window.location = "/login"
      }
    });
  };

  let bets = DEFAULT_BET_AMOUNTS
  // console.log('tournament = ', tournament)
  if (tournament && tournament.min_bet) {
    if (tournament.min_bet === "5.00") {
      bets = [5, 10, 20, 50, 100, 200]
    } else if (tournament.min_bet === "10.00") {
      bets = [10, 20, 50, 100, 200, 500]
    } else if (tournament.min_bet === "25.00") {
      bets = [25, 50, 100, 200, 300, 400]
    } else if (tournament.min_bet === "50.00") {
      bets = [50, 100, 200, 300, 500]
    } else if (tournament.min_bet === "100.00") {
      bets = [100, 200, 300, 400, 500, 1000]
    }
  }

  // console.log('type.name = ', type?.name)
  // console.log('bet = ', bet)
  // console.log('betOdd = ', betOdd)
  // console.log('(type.name === "Run play" || type.name === "Pass play") = ', (type.name === "Run play" || type.name === "Pass play"))
  // console.log('showBetAmounts = ', type?.name)
  const showBetAmounts = Boolean((!tournament || tournament.score_system !== 'most_bets_won'))
  const can_submit = ((type.name === "Run play" || type.name === "Pass play") ? (showBetAmounts ? Boolean(bet) : true)
    : (showBetAmounts ? Boolean(bet) : activeShow))
  let submitButtonDisabled = !can_submit;
  let componentHidden = ((type.name === "Run play" || type.name === "Pass play")? false : (betOdd === 0 || !betOdd)? true: false)
  // console.log('buttonDisabled = ', buttonDisabled)

  // console.log('token = ', token)
  // console.log('gsData = ', gsData)
  let user_coins = 0
  if (userBetsStats?.user?.total_coins) { // new WebSockets way (NBA)
    user_coins = parseFloat(userBetsStats.user.total_coins)
  } else if (gsData?.user?.total_coins) { // old polling way (NFL)
    user_coins = parseFloat(gsData.user.total_coins)
  }
  // console.log('user_coins = ', user_coins)
  //
  // bets.map((item) => {
  //   console.log('item = ', item)
  //   console.log('user_coins = ', user_coins)
  // })


  return (
    <div hidden={hide || componentHidden} style={{
      position: "fixed",
      left:0,
      right: 0,
      bottom: 0,
      zIndex: 99999}}>

    <Container className={classes.bottom}>
      {showBetAmounts && <Grid item>
          <ButtonGroup className={classes.buttonGroup} disableElevation>
            {bets.map((item) => (
              <Button
                className={(item === bet && betMountClicked)? classes.activeButton : classes.button}
                onClick={(e) =>{e.preventDefault(); setBet(item); setBetMountClicked(true)}}
                disabled={Boolean(item > user_coins)}
              >
                <Typography style={{float: 'left', fontFamily: 'Kanit', color: 'white'}}>{item}</Typography>
                <CopyrightIcon style={{float: 'left', height: 14, marginTop: 1, color: (item === bet && betMountClicked)?'white':'#FDC934'}}/>
              </Button>
            ))}
          </ButtonGroup>
        </Grid>}
      {showBetAmounts && <Grid item>
          <OddsPotential odds={betOdd} bet={bet} parentOdds={(type.name === "Run play" || type.name === "Pass play")? parentBet : 0}/>
        </Grid>}
        <Grid item className="betButton">
          <Button
            disabled={Boolean(submitButtonDisabled)}
            className={classes.betButton}
            onClick={handleSubmit}
          >
            PLACE CALL
          </Button>
        </Grid>
    </Container>
    </div>
  );
}
