import PlaysHeader from "../../ui/PlaysHeader";
import CldImage from '../../CldImage'
import React, {useEffect, useState} from "react";
import {Box, Button, TextField, Typography} from "@material-ui/core";
import {useStyles} from "../styles";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useTournDetailsStyles} from "./DetailsStyles";
import {useTournStyles} from "./ListingStyles";
import {formatDateToStr, utcDateStrToDate} from "../../../utils/dateUtils";
import ClockIcon from "@material-ui/icons/AccessAlarm";
import BasketballIcon from "@material-ui/icons/SportsBasketball";
import FootballIcon from "@material-ui/icons/SportsFootball";
import PlayerIcon from "@material-ui/icons/Person";
import CoinIcon from "../../icons/coin.png"
import {getTournaments, joinTournament} from "../../../hooks/useTournaments";
import constants from "../../../constants";
import closeX from '../../../components/icons/closeX.png'
import {getInviteUrl} from "../../../utils/inviteUtils";

const TournamentDetails = ({token, setIsShowBottomBar}) => {
    const classes = useStyles()
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams()
    const tournStyles = useTournStyles()
    const tournDetailsStyles = useTournDetailsStyles()

    const [sport,setSport] = useState(null)
    const [tournament,setTournament] = useState(null)
    const [showInviteCodeModal,setShowInviteCodeModal] = useState(false)
    const [inviteCode, setInviteCode] = useState(null)
    const [inviteCodeError, setInviteCodeError] = useState(null)
    const [theToken, setTheToken] = useState(token)
    const [linkModalCopyText, setLinkModalCopyText] = useState("COPY")

    const query = useQuery();

    function onTournamentPress(e) {
        // console.log('tournament.sport = ', tournament.sport)
        if (tournament.isUserEntered) {
            localStorage.setItem("tournament", JSON.stringify(tournament));
            // history.push("/game")
            if (tournament.sport === constants.SPORT_FOOTBALL) {
                navigate(
                    "/game",
                    { state: {
                        tournament
                    }
                });
            } else {
                navigate(
                    "/nba/game",
                    { state: {
                        tournament
                    }
                });
            }
        } else if (have_free_space) {
            /*
            let should_show_invite_code_modal = tournament.only_invited_players === 1
            if (should_show_invite_code_modal) {
                // the creator of the tournament can join
                should_show_invite_code_modal = theToken?.id !== tournament.createdByUserId
            }
            */
            const invite_code = query.get("inviteCode")
            let should_show_invite_code_modal = tournament.only_invited_players === 1 && !Boolean(invite_code)
                && theToken?.id !== tournament.createdByUserId

            if (should_show_invite_code_modal) {
                setShowInviteCodeModal(true)
            } else {
                joinTournament(theToken, tournament.id, invite_code).then(response => {
                    // console.log('Response2 = ', response)

                    fetchTournamentDetails(theToken, tournament.id, tournament.sport)
                });
            }
        } else {
            alert('Tournament is Full')
        }
    }

    function fetchTournamentDetails(token, tournament_id, sport_arg) {
        if (tournament_id) {
            getTournaments(token, tournament_id, sport_arg).then(response => {
                console.log('tournaments response = ', response)
                if (response && response.data && response.data.length > 0)
                    setTournament(response.data[0])
                else
                    setTournament(null)
            }).catch((error) => {
                console.log("Error getting the tournament with id = " + tournament_id + ":: ", error);
                if (error?.response?.status === 403 || error?.response?.status === 400) {
                    localStorage.removeItem("token");
                    window.location = "/login"
                }
            });
        }
    }

    function useQuery() {
        // const { search } = useLocation();
        const { search } = location

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(() => {
        setIsShowBottomBar(false)
        // alert(query.get("id"))

        // console.log("location=", location)
        // console.log("location.state.tournament=", location?.state?.tournament)
        let tournament_id;
        let sport;

        if (location?.state?.tournament) {
            setTournament(location.state.tournament);
            setSport(location.state.sport);

            tournament_id = location.state.tournament.id
            sport = location.state.sport
        } else if (query.get("id")) {
            tournament_id = query.get("id")
            sport = query.get("sport")
        } else {
            alert("Error: No tournament specified in URL")
        }

        let parsedToken = null
        if (token) {
            parsedToken = token
        } else {
            let userToken = localStorage.getItem("token");
            if (userToken) {
                let values = userToken.split(";");
                if (new Date(values[1]) < new Date()) {
                    localStorage.removeItem("token");
                } else {
                    // console.log("tourn id = ", query.get("id"))
                    parsedToken = JSON.parse(values[0]);
                    setTheToken(parsedToken);
                }
            }
        }

        if (parsedToken) {
            try {
                fetchTournamentDetails(parsedToken, tournament_id, sport)
            } catch (error) {
                alert(error)
            }
        } else {
            navigate("/login")
            localStorage.setItem("redirect_after_login", location.pathname + location.search)
        }
    }, [])

    function _closeInviteCodeModal() {
        setShowInviteCodeModal(false)
        setInviteCodeError(null)
    }

    function handleCopyLinkModal() {
        navigator.clipboard.writeText(getInviteUrl(tournament.inviteCode))
        setLinkModalCopyText("COPIED!")
        setTimeout(() => {
            setLinkModalCopyText("COPY")
        }, 2000)
    }

    // function _onSubmitInviteCode() {
    //     if (!inviteCode || inviteCode.trim().length === 0) {
    //         setInviteCodeError('Invite Code cannot be blank')
    //         return
    //     } else {
    //         setInviteCodeError(null)
    //     }
    //     joinTournament(token, tournament.id, inviteCode).then(response => {
    //         // console.log('Response3 = ', response)
    //         if (response?.data?.error) {
    //             setInviteCodeError(response.data.error)
    //         } else {
    //             fetchTournamentDetails(token, tournament.id, tournament.sport)
    //             setShowInviteCodeModal(false)
    //         }
    //     }).catch(response => {
    //         setInviteCodeError("There was a server error. Please try again, or contact info@playcaller.io")
    //     });
    // }

    function renderTournIsInviteOnlyModal() {
        const doInformationOnly = true
        const whiteBoxHeight = doInformationOnly ? "200px" : "276px"
        return (<div style={{width: '100%', height: '100vh', position: 'absolute', top: 0, left: 0}}>
            <div style={{display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                height: '100vh'
            }}>
                <div style={{width: '311px', height: whiteBoxHeight, backgroundColor: 'rgba(250, 250, 250, 1)', borderRadius: 6}}>
                    <div style={{display: 'flex', width: 'calc(100% - 16px)', height: '20px', marginTop: "16px", paddingRight: "20px", alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'row'}}>
                        <a href={"#"} style={{display: "block"}} onClick={_closeInviteCodeModal}>
                            <img src={closeX} style={{width: "14px", height: "14px"}}/>
                        </a>
                    </div>
                    <div style={{width: '100%', textAlign: "center", height: "20px", fontFamily: "Kanit", letterSpacing: "0.02em", color: "rgba(30, 33, 40, 1)"}}>INVITE-ONLY</div>
                    <div style={{width: 'calc(100% - 32px)', marginTop: "30px", marginLeft: "16px", marginRight: "16px", textAlign: "center", lineHeight: "18px", fontSize: "14px", fontFamily: "Kanit", color: "rgba(30, 33, 40, 0.6)"}}>You must access this tournament through an invite in order to join.</div>
                </div>
            </div>
        </div>)
    }

    if (!tournament) {
        return (<Box className={classes.body}>
            <PlaysHeader backGoesBack={true} showBackButton={true} showAccountIcon={true} data={token}/>
        </Box>)
    }

    const have_free_space = tournament.activeParticipants < tournament.totalParticipants;
    const start_date = utcDateStrToDate(tournament.startDatetimeUtc)
    // console.log('start_date = ', start_date.getTime())
    const is_started = start_date.getTime() < Date.now()
    const start_date_str = formatDateToStr(start_date)

    let end_date = null
    let end_date_str = null
    if (tournament.endDatetimeUtc) {
        end_date = utcDateStrToDate(tournament.endDatetimeUtc)
        end_date_str = formatDateToStr(end_date)
    }

    let main_btn_text = null

    if (!end_date_str) {
        if (tournament.isUserEntered) {
            main_btn_text = 'CLICK TO PLAY';
        } else if (have_free_space) {
            main_btn_text = 'JOIN';
        } else {
            main_btn_text = 'TOURNAMENT IS FULL';
        }
    }
    // console.log('tournament.imageId = ', tournament.imageId)

    return (<Box className={classes.body}>
        <PlaysHeader backGoesBack={false} backUri={"/tournaments"} showBackButton={true} showAccountIcon={true} data={token}/>
        <div className={tournDetailsStyles.main}>
            <div className={tournStyles.tourCellTopRow}>
                <div className={tournStyles.tournTitle}>{tournament.title}</div>
                {tournament.isUserEntered && <div className={tournStyles.tournTopBadge}>JOINED</div>}
            </div>
            <div className={tournStyles.tournSubTitle}>{tournament.type}</div>
            <div style={{width: '100%', marginTop: 12, marginBottom: 20}}>
                {/*<img style={{maxWidth: '100%'}} src={require('../../icons/nfl/tournament_cover_default.png').default}/>*/}
                {/*<img style={{maxWidth: '100%'}} src={'https://res.cloudinary.com/diop5ppii/image/upload/v1665959363/sample.jpg'}/>*/}
                {tournament.imageId !== null && <CldImage styles={{width: '100%'}} cloudName={constants.CLD_CLOUD_NAME} key={tournament.imageId} publicId={tournament.imageId}/>}
            </div>
            <div className={tournStyles.tournTileDetailsRow}>
                <div className={tournStyles.tournTileDetailsRowIcon}>
                    <ClockIcon className={tournStyles.tournTileDetailsRowIconImage} />
                </div>
                <div className={tournStyles.tournScheduleText}>
                    {(is_started ? "Started " : "") + start_date_str}
                </div>
            </div>
            {end_date_str && <div className={tournStyles.tournTileDetailsRow}>
                <div className={tournStyles.tournTileDetailsRowIcon}>
                    <ClockIcon className={tournStyles.tournTileDetailsRowIconImage} />
                </div>
                <div className={tournStyles.tournScheduleText}>
                    {"Ended " + end_date_str}
                </div>
            </div>}
            <div className={tournStyles.tournTileDetailsRow}>
                <div className={tournStyles.tournTileDetailsRowIcon}>
                    {tournament.sport === constants.SPORT_FOOTBALL && <FootballIcon className={tournStyles.tournTileDetailsRowIconImage} />}
                    {tournament.sport === constants.SPORT_BASKETBALL && <BasketballIcon className={tournStyles.tournTileDetailsRowIconImage} />}
                </div>
                <div className={tournStyles.tournTeamsTextContainer}>
                    <div className={tournStyles.tournTeamsTeam}>
                        {tournament.away}
                    </div>
                    <div className={tournStyles.tournTeamsTextVs}>
                        vs
                    </div>
                    <div className={tournStyles.tournTeamsTeam}>
                        {tournament.home}
                    </div>
                </div>
            </div>
            <div className={tournStyles.tournTileDetailsRow}>
                <div className={tournStyles.tournTileDetailsRowIcon}>
                    <PlayerIcon className={tournStyles.tournTileDetailsRowIconImage} />
                </div>
                <div className={tournStyles.tournTeamsTextContainer}>
                    <div className={tournStyles.tournParticipantCount}>
                        {tournament.activeParticipants} /
                    </div>
                    <div className={tournStyles.tournParticipantTotal}>
                        {tournament.totalParticipants}
                    </div>
                </div>
            </div>
            <div className={tournStyles.tournTileBottomRow}>
                {tournament.created_by_username && <div className={tournStyles.createdByWrap}><div className={tournStyles.createdByLabel}>
                    Created by
                </div>
                    <div className={tournStyles.createdByUser}>
                        &nbsp;{tournament.created_by_username}
                    </div></div>}
            </div>
            <div className={tournDetailsStyles.rules}>
                <div className={tournDetailsStyles.rulesHeader}>
                    RULES
                </div>
                <ul className={tournDetailsStyles.rulesList}>
                    {tournament.score_system !== 'most_bets_won' && <li className={tournDetailsStyles.rulesItem}>Minimum bet amounts are {tournament.min_bet} <img src={CoinIcon} style={{height: 12, marginTop: 2}}/></li>}
                    {tournament.score_system === 'most_bets_won' && <li className={tournDetailsStyles.rulesItem}>Winner is determined by total number of bets won</li>}
                    {tournament.score_system === 'most_gains' && <li className={tournDetailsStyles.rulesItem}>Winner is determined by highest total positive gains</li>}
                    {tournament.score_system === 'bets_won_x_gains' && <li className={tournDetailsStyles.rulesItem}>Winner is determined by total number of bets won times total positive gains</li>}
                    {sport === constants.SPORT_FOOTBALL && tournament.is_play_by_play === 1 && <li className={tournDetailsStyles.rulesItem}>Play-by-play betting</li>}
                    {sport === constants.SPORT_FOOTBALL && tournament.is_play_by_play === 0 && <li className={tournDetailsStyles.rulesItem}>Drive-by-drive betting</li>}
                    {tournament.only_invited_players === 1 && <li className={tournDetailsStyles.rulesItem}>Only invited players</li>}
                    {tournament.only_invited_players === 1 && tournament.players_can_invite === 1 && <li className={tournDetailsStyles.rulesItem}>Invited players can invite players</li>}
                    {/*<li className={tournDetailsStyles.rulesItem}>Format: {tournament.format}</li>*/}
                </ul>
            </div>

            {tournament.inviteCode && <div style={{
                // flexDirection: 'row',
                width: '100%',
                height: 17,
                marginTop: 50,
                marginBottom: 20
            }}>
                <TextField
                    variant={"outlined"}
                    label={"Invite Link"}
                    value={getInviteUrl(tournament.inviteCode)}
                    style={{color: '#fff', width: "100%"}}
                    InputProps={{
                        readOnly: 'true',
                        fontFamily: 'Kanit'
                    }}
                    InputLabelProps={{
                        style: {
                            fontSize: "18px",
                            color:"#fff",
                            opacity: "0.7",
                            fontFamily: 'Kanit'
                        }
                    }}
                />
                <Button style={{
                    width: '100px',
                    marginTop: 15,
                    marginBottom: 15,
                    letterSpacing: '0.1em',
                    fontSize: 16,
                    fontFamily: 'Kanit',
                    backgroundColor: '#efefef'
                }} onClick={handleCopyLinkModal}>{linkModalCopyText}</Button>
            </div>}

        </div>

        <div style={{width: '100%', height: 140, position: "fixed", bottom: 0, left: 0, backgroundColor: '#202327'}}>
            <div style={{width: '96%', height: 1, backgroundColor: 'rgba(255,255,255,0.1)', marginLeft: '2%'}}></div>
            <div style={{
                width: '100%',
                marginTop: 18,
                marginBottom: 12,
                flexDirection: 'row',
                height: 16
            }}>
                <div className={tournStyles.prize} style={{marginRight: 16}}>
                    <div className={tournStyles.prizeLabel}>
                        Prize:
                    </div>
                    <div className={tournStyles.prizeNumber}>
                        ${tournament.prize}
                    </div>
                    {/*<img src={CoinIcon} className={tournStyles.coin}/>*/}
                </div>
                <div className={tournStyles.entryFee} style={{float: "left"}}>
                    <div className={tournStyles.entryFeeLabel} style={{marginLeft: 16}}>
                        Entry fee
                    </div>
                    <div className={tournStyles.entryFeeNumber}>
                        ${tournament.entryFee}
                    </div>
                    {/*<img src={CoinIcon} className={tournStyles.coin}/>*/}
                </div>
            </div>
            {end_date_str && <Typography style={{paddingTop: 10, height: 20}}>This tournament has ended.</Typography>}
            {!end_date_str && <div style={{width: 'calc(100% - 32)', paddingLeft: 16, paddingRight: 16}}>
                <Button className={tournStyles.joinOrLeaveBtn} onClick={(e) => {
                    onTournamentPress(e)
                }}>
                    {main_btn_text}
                </Button>
            </div>}
        </div>

        {showInviteCodeModal && renderTournIsInviteOnlyModal()}
    </Box>);
}
export default TournamentDetails;
