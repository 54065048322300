import { makeStyles } from "@material-ui/core";
export const useTournStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: 14,
        lineHeight: '17px',
        letterSpacing: '0.1em',
        marginLeft: 10,
        fontFamily: 'Kanit'
    },
    sectionTitle2: {
        fontSize: 13,
        lineHeight: '15px',
        // letterSpacing: '0.1em',
        margin: 10,
        fontFamily: 'Kanit',
        color: 'rgba(255, 255, 255, 0.7)'
    },
    tournCell: {
        // display: 'block',
        width: 'calc(100% - 64px)',
        backgroundColor: '#2a2e35',
        borderRadius: 4,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        textAlign: 'left',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#363c45',
        }
    },
    tourCellTopRow: {
        flexDirection: 'row',
        width: '100%',
        height: 23,
        marginTop: 20,
        marginBottom: 10,
    },
    tourCellTopRow2: {
        flexDirection: 'row',
        width: '100%',
        height: 14,
        marginTop: 10,
        marginBottom: 10,
    },
    tournTitle: {
        width: '75%',
        float: "left",
        fontSize: 16,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: '23px',
        letterSpacing: '0.02em',
        color: '#FDC934',
        overflow: "hidden",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    tournTopBadge: {
        float: "right",
        paddingLeft: 8,
        paddingRight: 8,
        height: 21,
        borderRadius: 4,
        fontFamily: 'Kanit',
        fontSize: 14,
        lineHeight: "21px",
        color: '#FDC934',
        // color: '#FFF',
        border: '0.5px solid rgba(255,255,255,0.6)',
    },
    tournSubTitle: {
        width: '70%',
        fontSize: 10,
        fontFamily: 'Kanit',
        fontWeight: 500,
        lineHeight: "14px",
        letterSpacing: '0.1em',
        color: '#FFF',
        height: 14,
        float: 'left',
        //marginBottom: 20,
        textTransform: 'uppercase'
    },
    tournTileDetailsRow: {
        flexDirection: 'row',
        width: '100%',
        height: 17,
        marginBottom: 12.5
    },
    tournTileDetailsRowIcon: {
        float: 'left',
        height: 17,
        marginRight: 8,
        lineHeight: "17px",
    },
    tournTileDetailsRowIconImage: {
        height: 17, color: '#FDC934'
    },
    tournScheduleText: {
        float: 'left',
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "17px",
        letterSpacing: '0.1em',
        color: 'rgba(255, 255, 255, 0.6)',
    },
    tournTeamsTextContainer: {
        flexDirection: 'row',
        width: '100%',
        height: 17,
    },
    tournTeamsTeam: {
        float: "left",
        fontSize: 12,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "17px",
        letterSpacing: '0.02em',
        color: '#FFF',
        height: 17,
    },
    tournTeamsTextVs: {
        float: "left",
        fontSize: 10,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "17px",
        letterSpacing: '0.02em',
        color: 'rgba(255, 255, 255, 0.6)',
        height: 17,
        marginLeft: 6,
        marginRight: 6,
    },
    tournParticipantCount: {
        float: "left",
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "17px",
        letterSpacing: '0.02em',
        color: 'rgba(255, 255, 255, 0.6)',
        height: 17,
    },
    tournParticipantTotal: {
        float: "left",
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 500,
        lineHeight: "17px",
        letterSpacing: '0.02em',
        color: '#FFF',
        height: 17,
        marginLeft: 4
    },
    tournTileBottomRow: {
        width: '100%',
        marginTop: 18,
        marginBottom: 12,
        flexDirection: 'row',
        height: 16
    },
    createdByWrap: {
        float: "left",
    },
    createdByLabel: {
        float: "left",
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: '0.02em',
        color: 'rgba(255, 255, 255, 0.6)',
        height: 16,
        flexDirection: 'row',
    },
    createdByUser: {
        float: "left",
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: '0.02em',
        color: 'white',
        height: 16,
        flexDirection: 'row',
    },
    entryFee: {
        float: 'right',
        height: 16,
        marginRight: 20
    },
    entryFeeLabel: {
        float: "left",
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: '0.02em',
        color: 'rgba(255, 255, 255, 0.6)',
        height: 16,
        marginRight: 4
    },
    entryFeeNumber: {
        float: "left",
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: '0.02em',
        color: '#FFF',
        height: 16,
        marginRight: 4
    },
    prize: {
        float: 'right',
        height: 16,
        // marginRight: 4
    },
    prizeLabel: {
        float: "left",
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: '0.02em',
        color: 'rgba(255, 255, 255, 0.6)',
        height: 16,
        marginRight: 4
    },
    prizeNumber: {
        float: "left",
        fontSize: 14,
        fontFamily: 'Kanit',
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: '0.02em',
        color: '#FDC934',
        height: 16,
        marginRight: 4
    },
    coin: {
        float: "left",
        height: 12,
        // marginLeft: 2,
        marginTop: 1
    },
    joinOrLeaveBtn: {
        // display: 'block',
        // width: 'calc(100% - 32px)',
        width: '100%',
        marginTop: 18,
        marginBottom: 12,
        // marginLeft: 16,
        // marginRight: 16,
        backgroundColor: '#FDC934',
        borderRadius: 4,
        height: 40,
        lineHeight: "40px",
        fontSize: 18,
        color: 'white',
        fontFamily: 'Kanit',
        letterSpacing: '0.02em',
        textAlign: 'center',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#f0bd2b',
            color: '#fff',
        }
    },
    tournIsFull: {
        // display: 'block',
        // width: 'calc(100% - 32px)',
        width: '100%',
        marginTop: 12,
        marginBottom: 12,
        // backgroundColor: '#FDC934',
        // borderRadius: 4,
        height: 40,
        lineHeight: "40px",
        fontSize: 18,
        color: 'white',
        fontFamily: 'Kanit',
        letterSpacing: '0.02em',
        textAlign: 'center',
        fontWeight: 500,
    }
}))
