import {Button} from "@material-ui/core";
import React from "react";
import {checkToken} from "../../utils/userUtils";
import {useNavigate} from "react-router-dom";

import "./CreateTournamentButton.css"

function CreateTournamentButton({extraStyles = {}}) {
    const navigate = useNavigate();
    function createTournamentForm() {
        if (checkToken()) {
            localStorage.setItem("isShowBottomBar", "false")
            navigate('/tournament_create')
        }
    }

    return (<Button onClick={createTournamentForm} id="createTournamentBtn" style={extraStyles}>
        CREATE A TOURNAMENT
    </Button>)
}

export default CreateTournamentButton
