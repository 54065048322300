import {Box, Grid, Typography} from "@material-ui/core";
import {useStyles} from "../styles";
import React from "react";
import {Link} from "react-router-dom";

export const TournamentInvalid = ({msg1, msg2 = null}) => {
    const classes = useStyles();

    return (
        <Box className={classes.pauseScreen}>
            <Box className={classes.gamePaused}>
                <Grid container direction="column" spacing={2} style={{alignItems: "center"}}>
                    <Grid item className={classes.msgH1}>
                        {msg1 && <Typography className="msgGamePausedSml">{msg1}</Typography>}
                        {msg2 && <Typography className="msgGamePausedSml">{msg2}</Typography>}
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.linkToGame}>
                <Link to="/plays">
                    <Grid>
                        <Typography className="msgGamePaused" style={{textDecoration: "underline"}}>Go Back Home</Typography>
                    </Grid>
                </Link>
            </Box>
        </Box>
    );
}
