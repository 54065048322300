import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {Box} from "@material-ui/core";
import {useStyles} from "../styles";

const ValidateInvite = ({setIsShowBottomBar}) => {
    /*
     Not used - could be a possible alternative to what we have right now:
     * invite link is pointed to https://playcaller.io (Django back-end)
     * that does validation and redirects to the proper front-end page on https://app.playcaller.io
     */
    const classes = useStyles()
    const location = useLocation();
    // const { data } = useLoaderData();

    const query = useQuery();

    function useQuery() {
        // const { search } = useLocation();
        const { search } = location

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(() => {
        setIsShowBottomBar(false)

        if (query.get("id")) {
            console.log("Invite code = ", query.get("id"))
        } else {
            alert("Error: No invite codde specified in URL")
        }
    }, [])

    return (<Box className={classes.body}></Box>)
}
export default ValidateInvite;

// export async function validate_invite_loader({params}) {
//     console.log('validate_invite_loader, params = ', params)
//     return {}
// }
