import React, { useEffect, useState } from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./App.css";
import Game from "./components/layouts/Game/Game";
import GameNBA from "./components/layouts/Game/GameNBA";
import Leaderboard from "./components/layouts/Leaderboard/Leaderboard";
import Login from "./components/layouts/Login/Login";
import Plays from "./components/layouts/Plays/Plays";
import PrivacyPolicy from "./components/layouts/SignUp/PrivacyPolicy";
import SignUp from "./components/layouts/SignUp/SignUp";
import TermsOfService from "./components/layouts/SignUp/TermsOfService";
// import {validate_invite_loader} from "./components/layouts/Tournament/ValidateInvite";
import ValidateInvite from "./components/layouts/Tournament/ValidateInvite";
import { possesionOptions } from './service/getNbaOdds';
import { plays, targetPlayData } from './service/getPlaysOdds';
import Calls from "./components/layouts/Calls/Calls";
import TournamentListing from "./components/layouts/Tournament/Listing";
import BottomBar from "./components/ui/BottomBar";
import Rules from "./components/layouts/Game/Rules";
import TournamentCreate from "./components/layouts/Tournament/Create";
import TournamentDetails from "./components/layouts/Tournament/Details";
import {TournamentInvalid} from "./components/layouts/Tournament/TournamentInvalid";


function App() {
  const [token, setToken] = useState();
  const [, setIsRegisterClicked] = useState(false);
  const [gameType, setGameType] = useState("");
  const [selectedTabIdx, setSelectedTabIdx] = useState(0)
  const [isShowBottomBar, setIsShowBottomBar] = useState(false);
  const [tournamentId, setTournamentId] = useState(0);
  const [sportInApp, setSportInApp] = useState(null);

  // const history = useHistory()

  useEffect(() => {
    // console.log('App.js useEffect')
    const userToken = localStorage.getItem("token");
    const betsWonIds = localStorage.getItem("betsWonIds");
    const betsLostIds = localStorage.getItem("betsLostIds");
    const gameSelected = localStorage.getItem("gameSelected");
    const matchSelected = localStorage.getItem("matchSelected");
    // console.log('gameSelected2 = ', gameSelected)
    if (userToken) {
      let values = userToken.split(";");
      if (new Date(values[1]) < new Date()) {
        localStorage.removeItem("token");
        setToken(null)
        // window.location = "/"
      }
      else {
        const token = JSON.parse(values[0]);
        setToken(token);
      }
    } else {
      setToken(null)
      // if (window.location.pathname !== '/') {
      //   window.location = "/"
      // }
    }
    if(betsWonIds) {
      localStorage.removeItem("betsWonIds");
    }
    if(betsLostIds) {
      localStorage.removeItem("betsLostIds");
    }
    if(gameSelected) {
      setGameType(gameSelected);
    }

    document.body.style.overflowX = 'hidden'
    document.getElementById('root').style.overflowX = 'hidden'

    // window.addEventListener("storage", storageEventHandler)

    return () => {
      // window.removeEventListener("storage", storageEventHandler)
    }
  }, []);

  useEffect(() => {
    // console.log('Token = ', token)
    // console.log('History = ', history)
    // console.log('window.location = ', window.location)
    if (token) {

    } else if (window.location.pathname !== '/') {
      // // history.push("/")
      // window.location = "/"
    }
  }, [token])

  useEffect(() => {
    // console.log('App selectedTabIdx = ', selectedTabIdx)
  }, [selectedTabIdx])

  // function storageEventHandler(event) {
  //   console.log('event = ', event)
  //   if (event.key === 'isShowBottomBar') {
  //     setIsShowBottomBar(Boolean(event.newValue))
  //   }
  // }

  return (
      <BrowserRouter>
        {!localStorage.getItem("token") && <Login setToken={setToken} setIsRegisterClicked={setIsRegisterClicked}/> }
        {isShowBottomBar && <BottomBar
            selectedTabIdx={selectedTabIdx}
            setSelectedTabIdx={setSelectedTabIdx}
            tournamentId={tournamentId}
            sport={sportInApp}
        />}
        <Routes>
          <Route
              path="/"
              element={<Plays
                  token={token}
                  setGameType={setGameType}
                  setIsShowBottomBar={setIsShowBottomBar}
                  setSelectedTabIdx={setSelectedTabIdx}
                  setSportInApp={setSportInApp}
              />}
          />
          <Route
              path="/game"
              element={<Game
                  token={token}
                  plays={plays}
                  setToken={setToken}
                  targetPlayData={targetPlayData}
                  gameType={gameType}
                  possesionOptions={possesionOptions}
                  setIsShowBottomBar={setIsShowBottomBar}
                  setSelectedTabIdx={setSelectedTabIdx}
                  />}
          />
          <Route
              path="/nba/game"
              element={<GameNBA
                  token={token}
                  setToken={setToken}
                  gameType={gameType}
                  setIsShowBottomBar={setIsShowBottomBar}
                  setSelectedTabIdx={setSelectedTabIdx}
                  />}
          />
          <Route
              path="/tournaments"
              element={<TournamentListing
                  token={token}
                  setIsShowBottomBar={setIsShowBottomBar}
                  setSelectedTabIdx={setSelectedTabIdx}
                  setTournamentId={setTournamentId}
                  setSportInApp={setSportInApp}
              />}
          />
          <Route
              path="/tournament_create"
              element={<TournamentCreate
                  token={token}
                  setIsShowBottomBar={setIsShowBottomBar}
              />}
          />
          {/* the following is overkill - we just use invite links that use the Django web app instead of this */}
          <Route
              path="/tournament/invite/open"
              element={<ValidateInvite setIsShowBottomBar={setIsShowBottomBar}/>}
              loader={async ({ request }) => {
                console.log("loader")
                // loaders can be async functions
                const res = await fetch("http://localhost:8000/tournament/invite/open?id=NhwHorIGUA", {
                  signal: request.signal,
                });
                const user = await res.json();
                return user;
              }}
          />
          <Route
              path="/tournament/details"
              element={<TournamentDetails
                  token={token}
                  setIsShowBottomBar={setIsShowBottomBar}
              />}
          />
          <Route
              path="/tournament/invalid_invite"
              element={<TournamentInvalid
                  msg1={"Sorry, that's not a valid tournament invite"}
              />}
          />
          <Route
              path="/tournament/invalid"
              element={<TournamentInvalid
                  msg1={"Sorry, that's not a valid tournament"}
              />}
          />
          <Route
              path="/calls"
              element={<Calls
                  token={token}
                  setIsShowBottomBar={setIsShowBottomBar}
                  setSelectedTabIdx={setSelectedTabIdx}
                  sport={sportInApp}
              />}
          />
          <Route
              path="/leaderboard"
              element={<Leaderboard
                  token={token}
                  setIsShowBottomBar={setIsShowBottomBar}
                  setSelectedTabIdx={setSelectedTabIdx}
              />}
          />
          <Route
              path="/plays"
              element={<Plays token={token}
                     setGameType={setGameType}
                     setIsShowBottomBar={setIsShowBottomBar}
                     setSelectedTabIdx={setSelectedTabIdx}
                     setSportInApp={setSportInApp}
              />}
          />
          <Route
              path="/game_rules"
              element={<Rules token={token} />}
          />
          <Route
              path="/signUp"
              element={<SignUp setToken={setToken} />}
          />
          <Route
              path="/termsOfService"
              element={<TermsOfService />}
          />
          <Route
              path="/privacyPolicy"
              element={<PrivacyPolicy />}
          />
        </Routes>
      </BrowserRouter>
  );
}
export default App;
