import {
  Box, Checkbox, Container, FormControl, FormControlLabel, Grid,
  TextField, Typography, withStyles
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useRef, useState } from "react";
import { doLogin, rememberMe } from "../../../hooks/login";
import logoLogin from "../../icons/logoLogin.png";
import { useStyles } from "../styles";
import "./login.css";

// const CustomInput = withStyles({
//   root: {
//     "& label.Mui-focused": {
//       color: "#FDC934",
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: "green",
//     },
//     "& .MuiOutlinedInput-root": {
//       color: '#fff',
//       backgroundColor: "#2A2D35",
//       opacity: 0.6,
//
//       "&:hover fieldset": {
//       borderColor: "#FDC934",
//       },
//       "&.Mui-focused fieldset": {
//       borderColor: "#FDC934",
//       },
//     },
//   },
// })(TextField);

const CustomCheckbox = withStyles({
  root: {
    color: "#FFF",
    opacity: 0.4,
    borderColor: "#FFFFFF",
    "&$checked": {
      opacity: 1,
      color: "#FDC934",
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

function Login({ setToken, setIsRegisterClicked }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [isLogoVisible, setIsLogoVisible] = useState(true);
  const navigate = useNavigate();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [hide, setVisibility] = useState(true);
  const isFirstRender = useRef(true);

  const handleSignUp = () => {
    setIsRegisterClicked(true);
  }


  const handleButtonPress = (e=null) => {
    if (e)
      e.preventDefault();
    if (username && password) {
      let params = {
        username: username,
        password: password,
      };

      doLogin(params)
        .then((response) => {
          // console.log('Login Response = ', response)
          if (response?.data?.error) {
            var errorMsg = response.data.error || "";
            setErrorMessage(errorMsg);
            return
          }
          if (response?.data?.id) {
            setToken(response.data);

            let values = [];
            let expiryDate = new Date();
            values.push(JSON.stringify(response.data));
            if (isChecked) {
              expiryDate.setHours(expiryDate.getHours() + 120);
              values.push(expiryDate);
              localStorage.setItem("token", values.join(";"));
            }
            else {
              expiryDate.setHours(expiryDate.getHours() + 24);
              values.push(expiryDate);
              localStorage.setItem("token", values.join(";"));
            }
          }
          const saved_redirect = localStorage.getItem("redirect_after_login")

          if (saved_redirect) {
            localStorage.removeItem("redirect_after_login")
            navigate(saved_redirect);
          } else {
            navigate("/plays");
          }

        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } else {
      alert("Please enter username and password");
    }
  };

  useEffect(() => {
    if (
      !isFirstRender.current &&
      isChecked &&
      password !== "" &&
      username !== ""
    ) {
      let params = {
        username: username,
        password: password,
      };
      rememberMe(params);
    }
  }, [username, password, isChecked]);

  useEffect(() => {
    const userToken = localStorage.getItem("token");

    if (userToken) {
      let values = userToken.split(";");
      if (window.location.pathname !== '/plays' && new Date(values[1]) > new Date()) {
        window.location = "/plays"
        // console.log('Login blah = ', new Date(values[1]))
      }
    }

    // if (localStorage.rememberMe === "true") {
    //   setUserName(localStorage.username);
    //   setPassword(localStorage.password);
    // }

    // if (localStorage.username !== null) {
    //   fetchData();
    // }
    isFirstRender.current = false; // toggle flag after first render/mounting
  }, []);

  const hideSwitch = (ev) => {
    setVisibility(!hide);
  };

  const _onTextFieldFocus = (e) => {
    // alert(navigator.appVersion)
    if (navigator.appVersion && navigator.appVersion.indexOf('Android') >= 0)
      setIsLogoVisible(false)
  }

  const _onTextFieldBlur = (e) => {
    setIsLogoVisible(true)
  }

  const textFieldOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleButtonPress()
    }
  }

  return (
    <Box className={classes.loginBody}>
      {/*<Container className={classes.logoLogin}></Container>*/}
      {isLogoVisible && <div className={classes.logoLoginImgWrap}>
        <img src={logoLogin} className={classes.logoLoginImg}></img>
      </div>}
      <Box className={classes.form} autoCapitalize="none">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography className={classes.signIn}>SIGN IN</Typography>
          </Grid>
          <Grid item>
            <Typography className={errorMessage? "error-label" : "error-label-invisible"}>* {errorMessage}</Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <TextField
                className={classes.input }
                autoFocus={true}
                variant="outlined"
                label="Username"
                type="text"
                value={username}
                autoCapitalize="none"
                autoComplete="off"
                inputProps={{
                  autoCapitalize: 'none',
                }}
                onChange={(e) => setUserName(e.target.value)}
                onKeyPress={textFieldOnKeyPress}
                InputLabelProps={{
                  style: {
                    fontSize: "12px",
                    color:" #FFF",
                    opacity: "0.7",
                  } }}
                onFocus={_onTextFieldFocus}
                onBlur={_onTextFieldBlur}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
               <TextField
                className={classes.input}
                variant="outlined"
                type={!hide ? "text" : "password"}
                label="Password"
                value={password}
                autoCapitalize="none"
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={textFieldOnKeyPress}
                InputProps={{
                  endAdornment:
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={hideSwitch} edge="end" style={{  color:" #FFF", opacity: "0.7"}}>
                      {hide ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
              InputLabelProps={{
                style: {
                  fontSize: "12px",
                  color:" #FFF",
                  opacity: "0.7",
                } }}
                onFocus={_onTextFieldFocus}
                onBlur={_onTextFieldBlur}
               />
            </FormControl>
          </Grid>
        </Grid>
        {/*<Grid item>
          <Typography className={classes.forgotPass}>
            Forgot my password?
          </Typography>
        </Grid>*/}
        <Grid item>
          <FormControlLabel
            className={classes.rememberMe}
            control={
              <CustomCheckbox
                type="checkbox"
                id="vehicle1"
                name="RememberMe"
                onChange={(e) => setChecked(e.target.checked)}
                checked={isChecked}
              />
            }
            label="Remember Me"
          />
        </Grid>
        <Grid item>
          <button
            name="login"
            className="login-button"
            onClick={handleButtonPress}
          >
            SIGN IN
          </button>
        </Grid>
        <Grid item>
          <div className="registerLink">
            Don't have an account?&nbsp;
            <Link to="/signUp" className="signUp">
             Sign up
            </Link>
          </div>
        </Grid>
      </Box>
    </Box>
  );
}
export default Login;
