export const checkToken = () => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
        let values = userToken.split(";");
        if (new Date(values[1]) < new Date()) {
            localStorage.removeItem("token");
            window.location = "/login"
            console.log('Plays: Expired token')
            return false
        } else {
            // const token = JSON.parse(values[0]);
            // setToken(token);
        }
    } else {
        window.location = "/login"
        console.log('Plays: No userToken')
        return false
    }
    return true
}
